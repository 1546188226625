import React, { useState, useEffect } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { RiArrowDownSFill } from "react-icons/ri";

import {
  Input,
  Select,
  message,
  TableColumnsType,
  UploadProps,
  DatePicker,
  Spin,
} from "antd";
import "antd/dist/reset.css";

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import GradeIcon from "@mui/icons-material/Grade";
import {
  addToFreebies,
  allbanner,
  allProducts,
  deleteProduct,
  updateDiscount,
  updateProduct,
} from "../Api/Apicalls";
import ApiEndPoints from "../Api/Apiendpoints";
import { API_URL } from "../Api/Config";
import { MdClear } from "react-icons/md";
import { Box } from "@mui/material";
import style from "antd/es/affix/style";
import TextArea from "antd/es/input/TextArea";
import Dragger from "antd/es/upload/Dragger";
import { Api, InboxOutlined } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import Upload, { RcFile } from "antd/es/upload";
import { Table } from "antd";
import type { DatePickerProps, RadioChangeEvent } from "antd";
import { Radio } from "antd";
import { Editor } from "primereact/editor";
import axios, { AxiosResponse } from "axios";

const Products = () => {
  // State for modal visibility and selected product
  const [opens, setOpens] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [isload, setIsload] = useState(false)

  // console.log(selectedProduct,"selectedProduct");

  const [file, setFile] = useState<RcFile | null>(null);

  // State for product data
  const [productDatas, setProductDatas] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [value, setValue] = useState("escalation");
  const [tagupdate, setTagupdate] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [uploadedSubImages, setUploadedSubImages] = useState<any[]>([]);
  const [ogDatas, setOgDatas] = useState<any[]>([])

  const [subImages, setSubImages] = useState<string[]>(
    selectedProduct?.subimages || []
  );

  // Fetch all products
  useEffect(() => {
    getAllProduct();
  }, []);

  const getAllProduct = async () => {
    try {
      setIsload(true)
      const firstResponse = await allProducts(ApiEndPoints("getproduct"));
      const reversedData = [...firstResponse.products].reverse();
      setProductDatas(reversedData);
      setOgDatas(reversedData)
      setTotalPages(Math.ceil(firstResponse.products.length / 10)); // Adjust pagination
      setIsload(false)
    } catch (error) {
      console.error(error);
    }
  };

  // Handle opening modal and setting selected product
  const handleOpens = (product: any) => {
    setSelectedProduct(product);
    setOpens(true);
  };

  // Handle closing modal
  const handleCloses = () => {
    setOpenDiscount(false);
    setOpens(false);
    setSelectedProduct(null);
  };

  // const handleDeleteImage = (val:any,index: number) => {
  //   console.log(selectedProduct._id);

  //   // Create a copy of subImages to avoid mutating the state directly
  //   const updatedImages = [...subImages];
  //   updatedImages.splice(index, 1); // Remove the image at the specified index
  //   console.log(updatedImages);

  //   setSubImages(updatedImages); // Update the state with the new array
  // };

  const handleDeleteImage = async (val: any, index: any) => {
    const productId = selectedProduct._id; // Product ID
    const imageName = val;
    const token = localStorage.getItem("dashtoken"); // Retrieve the token from local storage
    const updatedImages = [...subImages];
    updatedImages.splice(index, 1);
    if (typeof imageName !== "string") {
      // Skip the API call if the image name is not a string
      setSubImages(updatedImages);
      setSelectedProduct({
        ...selectedProduct,
        subimages: updatedImages,
      });
      return;
    }
    try {
      // Make the DELETE request
      const response = await axios.post(
        `${API_URL}/api/product/deleteSubimage`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
          data: {
            productId,
            imageName,
          },
        }
      );

      // Remove the image from the local state
      const updatedImages = [...subImages];
      updatedImages.splice(index, 1); // Remove the image at the specified index
      setSubImages(updatedImages);

      // setSelectedProduct(updatedImages);
      successmessage("Deleted successfully!");
      console.log("Image deleted successfully:", response.data);
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  // Handle updating the product
  const handleUpdateProduct = async () => {
    if (!selectedProduct) return;

    try {
      const formData = new FormData();
      Object.keys(selectedProduct).forEach((key) => {
        if (
          selectedProduct[key] !== undefined &&
          selectedProduct[key] !== null
        ) {
          formData.append(key, selectedProduct[key]);
        }
      });

      if (newZip) {
        formData.append("zip", newZip);
      }
      if (selectedProduct.productTags) {
        console.log(selectedProduct.productTags, "productTags");

        formData.append("productTags", selectedProduct.productTags);
      }

      if (subImages && subImages.length > 0) {
        subImages.forEach((subImage, i) => {
          // Assuming subImage is a File object
          formData.append(`subimage`, subImage);
        });
      }

      const response = await updateProduct(
        ApiEndPoints("updateProduct"),
        selectedProduct._id,
        formData
      );
      successmessage("Updated successfully!");
      setOpens(false);
      getAllProduct();
    } catch (error: any) {
      console.warn("Error updating product:", error);
      errormessage(error.response.data.message);
    }
  };

  // Delete Product

  const handleDeleteProduct = async (id: any) => {
    try {
      const response = await deleteProduct(ApiEndPoints("deleteProduct"), id);
      console.log("Product deleted:", response);
      getAllProduct();
    } catch (err) {
      console.error(err);
    }
  };

  // Handle form input changes
  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;

    // Omit subimages while updating other fields
    const { subimages, ...rest } = selectedProduct;

    setSelectedProduct({ ...rest, [name]: value });
  };

  // Table columns
  const columns: TableColumnsType = [
    {
      title: "Product Id",
      dataIndex: "_id",
      render: (text: string) => <>{text.slice(-5)}</>,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Type",
      dataIndex: "category",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Buying's",
      dataIndex: "buyersCount",
    },
    {
      title: "Ratings",
      dataIndex: "ratingsAverage",
      render: (text: string) => (
        <>
          <GradeIcon className="rating" />
          {text}
        </>
      ),
    },
    {
      title: "Image",
      dataIndex: "subimages",
      render: (text: [string]) => (
        <img
          src={`${API_URL}/public/subimages/${text[0]}`}
          alt="Product"
          style={{ width: 50, height: 50 }}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "isDeleted",
      render: (text: string, record: any) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <EditOutlined onClick={() => handleOpens(record)} />
          <DeleteOutlined onClick={() => handleDeleteProduct(record._id)} />
        </div>
      ),
    },
  ];

  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedValues, setSelectedValues] = useState<string | null>(null);
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [openDiscount, setOpenDiscount] = useState(false);

  const beforeUpload = (file: RcFile) => {
    // Store the selected file in the state
    setFile(file);
    setImageUrl(URL.createObjectURL(file));
    return false;
  };

  const [newZip, setNewZip] = useState<RcFile | null>(null);
  const beforeUploadZip = (file: RcFile) => {
    const isZip =
      file.type === "application/zip" ||
      file.type === "application/x-zip-compressed";

    if (!isZip) {
      message.error("Only ZIP files are allowed!");
      return Upload.LIST_IGNORE;
    }

    setNewZip(file);
    return false;
  };

  const uploadPropsZip: UploadProps = {
    name: "file",
    beforeUpload: beforeUploadZip,
  };

  const MAX_IMAGES = 6;

  const handleUpload = (fileList: any[]) => {
    if (fileList.length > MAX_IMAGES) {
      message.error(`You can only upload up to ${MAX_IMAGES} images.`);
      return;
    }
    setUploadedSubImages(fileList);
    message.success("Images updated successfully.");
  };
  const maxImages = 6;
  const props = {
    name: "file",
    multiple: true,
    beforeUpload: (file: any) => {
      if (subImages.length >= maxImages) {
        message.error("You can only upload up to 6 images.");
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    customRequest: async ({ file, onSuccess, onError }: any) => {
      try {
        const formData = new FormData();
        formData.append("subimage", file);
        // Replace with your upload endpoint
        // const response = await axios.patch(`${API_URL}/api/upload`, formData);
        setSubImages((prev) => {
          const updatedSubImages = [...prev, file]; // Add the new file
          console.log(updatedSubImages, "Updated SubImages");
          return updatedSubImages;
        });
        console.log(file);

        console.log(subImages, "subImages");
        onSuccess("Uploaded successfully.");
        message.success(`${file.name} uploaded successfully.`);
      } catch (error) {
        console.error(error);
        onError(error);
        message.error(`${file.name} upload failed.`);
      }
    },
  };

  const handleSelectChange = (value: string, fieldName: string) => {
    setSelectedProduct((prevProduct: any) => ({
      ...prevProduct,
      [fieldName]: value, // Update the specific field with the selected value
    }));
  };
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const handledropdown = (value: string[], fieldName: string) => {
    setSelectedProduct((prevProduct: any) => ({
      ...prevProduct,
      [fieldName]: value,
    }));
  };

  const handleSelectChangeTag = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // Extract both value and label for each selected option
    const selectedOptions = Array.from(e.target.selectedOptions).map(
      (option) => ({
        value: option.value,
        label: option.label,
      })
    );

    setTagupdate(selectedOptions[0].label);
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedTags(selectedValues);
    handledropdown(selectedValues, "tags");
  };

  const handleSelectedProdTags = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedTags(selectedOptions);
    handledropdown(selectedOptions, "productTags");
  };

  interface productData {
    [x: string]: any;
    category: string;
    name: string;
    price: string | number;
    description: string;
    sizes: string;
    stitchCount: string | number;
    colors: string | number;
    formats: string;
    quantity: string;
    slug: string;
    tags: string;
    metaContent: string;
    metaHeading: string;
    keywords: String;
  }
  const [productData, setProductData] = useState<productData>({
    category: "",
    name: "",
    price: "",
    description: "",
    sizes: "",
    stitchCount: "",
    colors: "",
    formats: "",
    quantity: "",
    slug: "",
    tags: "",
    metaContent: "",
    metaHeading: "",
    keywords: "",
  });

  const successmessage = (content: string) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const errormessage = (content: string) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  // Freebies

  // State for modal visibility and selected product
  const [openk, setOpenk] = useState(false);

  const handleRowSelectionChange = (selectedKeys: React.Key[]) => {
    setSelectedRowKeys(selectedKeys);
  };

  const handleButtonClick = async (
    _handleRowSelectionChange: (selectedKeys: React.Key[]) => void
  ) => {
    if (selectedRowKeys.length === 0) {
      errormessage("No product selected!");
      return;
    }

    // Get the first ID from the selectedRowKeys array
    const idToShow = selectedRowKeys[0];
    const token = localStorage.getItem("dashtoken");
    if (!token) {
      errormessage("Logout and Login!");
      return;
    }
    try {
      const updatedProducts = [];

      for (const idToShow of selectedRowKeys) {
        // Update the selectedProduct to have a price of 0 and mark as a freebie
        const updatedProduct = { ...selectedProduct, isFreebie: true };

        // const response = await addToFreebies(
        //   ApiEndPoints("addToFreebies"),
        //   idToShow,
        //   updatedProduct
        // );

        // const res=await axios.patch(`${API_URL}/api/product/${idToShow}`,updatedProduct,{
        //   headers:{
        //     Authorization:`Bearer ${token}`
        //   }
        // })

        const response = await updateProduct(
          ApiEndPoints("updateProduct"),
          idToShow,
          {
            isFreebie: true,
          }
        );

        updatedProducts.push(updatedProduct);
      }

      navigate("/freebies", { state: { products: updatedProducts } });

      setOpenk(false);
      getAllProduct();
    } catch (error: any) {
      // errormessage(error.response.data);
      console.log(error);

      // errormessage( error.response?.data || error.message);
    }
  };

  const handleDiscountClick = () => {
    if (selectedRowKeys.length === 0) {
      errormessage("No product selected!");
      return;
    }
    setOpenDiscount(true);
  };

  // Filter Product
  interface filterData {
    byProductTag: string;
    byProductName: string;
    byProductId: string | null;
    byProductCategory: string;
  }

  const [filterData, setfilterData] = useState<filterData>({
    byProductTag: "",
    byProductName: "",
    byProductId: "",
    byProductCategory: "",
  });

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setfilterData({
      ...filterData,
      byProductId: e.target.value || null,
    });
  };

  const handleFilter = async () => {
    try {
      setIsload(true);

      const response: AxiosResponse<any> = await axios.get(`${API_URL}/api/product/filterProducts?tags=${filterData.byProductTag}&productId=${filterData.byProductId}&categories=${filterData.byProductCategory}`);
      console.log(response.data.products, "response.data.products");
      setProductDatas(response.data.products);
      setfilterData({
        byProductTag: "",
        byProductName: "",
        byProductId: "",
        byProductCategory: "",
      });
      setIsload(false);
    } catch (error) {
      console.error("Error fetching or filtering products:", error);
      setIsload(false);
    }
  };




  const [discountData, setDiscountData] = useState<{
    ids: any;
    pricePercentage: number | null;
    priceValidityDate: string;
    priceUpdateType: string;
  }>({
    ids: [],
    pricePercentage: null,
    priceValidityDate: "",
    priceUpdateType: value,
  });

  const [tags, setTags] = useState<any>({
    tagName: "",
    tagValue: "",
  });

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const handleupdateDiscount = async () => {
    if (!discountData.pricePercentage) {
      errormessage("Please select a valid percentage only number.");
      return;
    }
    if (!discountData.priceValidityDate) {
      errormessage("Please select a valid price validity date.");
      return;
    }
    const data = {
      ids: selectedRowKeys,
      pricePercentage: discountData.pricePercentage,
      priceValidityDate: discountData.priceValidityDate,
      priceUpdateType: value,
    };
    try {
      const response = await updateDiscount(
        ApiEndPoints("bulkupdateProduct"),
        data
      );
      successmessage("Discount updated!");
      setOpenDiscount(false);
      setDiscountData({
        ids: [],
        pricePercentage: null,
        priceValidityDate: "",
        priceUpdateType: value,
      });
    } catch (err: any) {
      errormessage(err.response.message);
    }
  };

  const chunkArray = (arr: any[], chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const [isDropdown, setIsdropdown] = useState(false);
  const [allTag, setAllTag] = useState<any[]>([]);

  const chunkedTags = chunkArray(tags, 10);

  const getAllTags = async () => {
    try {
      // const response = await allProducts(ApiEndPoints("productTags"));

      const response = await axios.get(`${API_URL}/api/productTag`);

      setAllTag(response.data.productTags);

      const formattedTags = response.data.productTags.map((tag: any) => ({
        value: tag._id,
        label: tag.tagName,
      }));
      console.log(formattedTags, "feee");
      setTags(formattedTags);
    } catch (error: any) {
      console.log(error);
      // error.message(error.response.message);
    }
  };

  useEffect(() => {
    getAllTags();
  }, []);

  useEffect(() => {
    const formattedTags = allTag.map((tag) => ({
      value: tag.tagValue,
      label: tag.tagName,
    }));
    setTags(formattedTags);
  }, [allTag]);

  const [prodTags, setProdTags] = useState<any[]>([]);

  const getAllProdTags = async () => {
    try {
      const response = await allbanner(ApiEndPoints("addtags"));
      const formatProdtag = response.productTags.map((tag: any) => ({
        value: tag.tagName,
        label: tag.tagName,
      }));
      setProdTags(formatProdtag);
    } catch (error: any) {
      errormessage(error.response.message);
    }
  };

  useEffect(() => {
    getAllProdTags();
  }, []);

  useEffect(() => {
    if (selectedProduct && selectedProduct.subimages) {
      // Update subImages whenever selectedProduct.subimages changes
      setSubImages(selectedProduct.subimages);
    } else {
      // Set subImages to an empty array if no subimages are present
      setSubImages(subImages);
    }
  }, [selectedProduct]);

  // useEffect(() => {
  //   // Perform any additional logic or updates when subImages changes
  //   console.log("Subimages updated:", subImages);
  //   setSubImages(subImages);

  // }, [selectedProduct.subimages]);

  // console.log(selectedProduct?.productTags);

  return (
    <>
      {contextHolder}
      <section className="py-4">
        <Container>
          <div className="d-flex justify-content-between">
            <div>
              <h2 className="title">Products</h2>
            </div>
            <div className="d-flex">
              <div className="me-2">
                <button
                  className="apply-btn"
                  onClick={() => handleButtonClick(handleRowSelectionChange)}
                >
                  Add to Freebies
                </button>
              </div>
              <div className="me-2">
                <button className="apply-btn" onClick={handleDiscountClick}>
                  Add to Discount
                </button>
              </div>
            </div>
          </div>
          <div className="row pb-4">
            {/* <div className="col-lg-2 col-md-2 col-sm-4">
              <div>
                <label className="label">Name</label>
                <Input
                  className="input"
                  value={filterData.byProductName}
                  onChange={(e) =>
                    setfilterData({
                      ...filterData,
                      byProductName: e.target.value,
                    })
                  }
                />
              </div>
            </div> */}

            <div className="col-lg-2 col-md-2 col-sm-4">
              <div>
                <label className="label">Tags</label>
                <br />
                <Select
                  className="input"
                  style={{ width: "100%" }}
                  value={filterData.byProductTag} // Using 'byProductTag' for tags in filterData
                  onChange={(value) => {
                    // Update 'byProductTag' in the filterData state when a tag is selected
                    setfilterData({ ...filterData, byProductTag: value });
                  }}
                >
                  {/* Loop through the tags directly without grouping */}
                  {chunkedTags.flat().map((val: any, i: any) => (
                    <Select.Option key={i} value={val.label.toLowerCase()}>
                      {val.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>


            <div className="col-lg-2 col-md-2 col-sm-4">
              <div>
                <label className="label">Product Id</label>
                <Input
                  className="input"
                  value={filterData.byProductId || ""}
                  onChange={handleFilterChange}
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-4">
              <div>
                <label className="label">Categories</label>
                <br />
                <Select
                  className="input"
                  style={{ width: 120 }}
                  value={filterData.byProductCategory}
                  onChange={(value) =>
                    setfilterData({ ...filterData, byProductCategory: value })
                  }
                  options={[
                    {
                      value: "print design",
                      label: "Print design",
                    },
                    {
                      value: "embroidery design",
                      label: "Embroidery design",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-2  col-sm-4 col-4">
              <div className="mt-2">
                <Button
                  variant="contained"
                  className="btn-apply"
                  onClick={handleFilter}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            {isload ?

              <><div style={{ height: "70vh", display: "flex", justifyContent: "center", alignItems: "center" }} className="text-center"><Spin /></div></>
              :
              <Table
                rowKey="_id"
                rowSelection={{
                  selectedRowKeys,
                  onChange: handleRowSelectionChange,
                }}
                columns={columns}
                dataSource={productDatas}
              />}
            {/* Modal for editing product */}
            <Modal
              show={opens}
              onHide={handleCloses}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Modal.Header className="d-flex justify-content-between">
                <Modal.Title>Edit Product</Modal.Title>
                <MdClear onClick={handleCloses} style={{ cursor: "pointer" }} />
              </Modal.Header>
              <Modal.Body>
                {selectedProduct && (
                  <Box sx={{ ...style }}>
                    <div className="row">
                      <div
                        className="col-lg-6 col-md-6 col-sm-12"
                        style={{ width: "100%" }}
                      >
                        <div>
                          <label className="label py-2">Product Name</label>
                          <Input
                            value={selectedProduct.name}
                            placeholder=""
                            name="name"
                            onChange={handleInputChange}
                          />
                          <label className="label py-2">Upload image</label>
                          <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined style={{ color: "#FF518A" }} />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                          </Dragger>
                          {subImages && subImages.length > 0 && (
                            <>
                              {imageUrl === "" ? (
                                subImages.map((val: any, i: any) => (
                                  <div
                                    key={i}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      marginRight: 10,
                                    }}
                                  >
                                    {val instanceof File ||
                                      val instanceof Blob ? (
                                      <img
                                        src={URL.createObjectURL(val)} // Only createObjectURL if val is a File or Blob
                                        style={{ width: "20%" }}
                                        alt={`Subimage ${i + 1}`}
                                      />
                                    ) : (
                                      <img
                                        src={`${API_URL}/public/subimages/${val}`}
                                        style={{ width: "20%" }}
                                        alt={`Subimage ${i + 1}`}
                                      />
                                    )}

                                    <button
                                      style={{
                                        background: "red",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleDeleteImage(val, i)} // Calls the delete function
                                    >
                                      ×
                                    </button>
                                  </div>
                                ))
                              ) : (
                                <img
                                  src={imageUrl}
                                  style={{ width: "20%" }}
                                  alt="Selected"
                                />
                              )}
                            </>
                          )}

                          <br />
                          <label className="label py-2">Upload Zip file</label>
                          <Dragger {...uploadPropsZip}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined style={{ color: "#FF518A" }} />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                          </Dragger>
                          {newZip ? newZip.name : selectedProduct.zip}
                          <br />
                          <label className="label py-2">Price</label>
                          <Input
                            name="price"
                            placeholder=""
                            value={selectedProduct.price}
                            onChange={handleInputChange}
                          />
                          <label className="label py-2">Category</label>
                          <select
                            name="category"
                            id="category"
                            value={selectedProduct?.category || ""} // Set the selected value from the state
                            onChange={(e) =>
                              handleSelectChange(e.target.value, "category")
                            } // Get the selected value on change
                            style={{
                              width: "100%",
                              height: "30px",
                              border: "1px solid rgb(219 219 219)",
                              borderRadius: "5px",
                            }}
                          >
                            <option value="print design">Print design</option>
                            <option value="embroidery design">
                              Embroidery design
                            </option>
                          </select>
                          <label className="label py-2">Product Tags</label>
                          <Select
                            mode="multiple"
                            placeholder="Select Product Tags"
                            value={
                              Array.from(
                                new Set(selectedProduct?.productTags)
                              ) || []
                            }
                            onChange={(value) => {
                              // Remove duplicates
                              const uniqueTags = Array.from(new Set(value));
                              // Update the state with unique tags
                              setSelectedProduct({
                                ...selectedProduct,
                                productTags: uniqueTags,
                              });
                            }}
                            style={{ width: "100%" }}
                            options={prodTags}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                          />

                          {(selectedProduct?.category === "embroidery design" ||
                            selectedValue === "embroidery design") && (
                              <div>
                                <div className="input-container">
                                  <label className="input-label">
                                    Sizes(Inches)
                                  </label>
                                  &nbsp;&nbsp;
                                  <Input
                                    placeholder="Eg 7 * 7"
                                    className="rg-input"
                                    name="sizes"
                                    value={selectedProduct.sizes}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="input-container">
                                  <label className="input-label">
                                    Stitch Count
                                  </label>
                                  &nbsp;&nbsp;
                                  <Input
                                    placeholder="Eg 38982"
                                    className="rg-input"
                                    name="stitchCount"
                                    value={selectedProduct.stitchCount}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                {/* <div className="input-container">
                                <label className="input-label">Colors</label>
                                &nbsp;&nbsp;
                                <Input
                                  placeholder="Eg 14"
                                  className="rg-input"
                                  name="colors"
                                  value={selectedProduct.colors}
                                  onChange={handleInputChange}
                                />
                              </div> */}

                                <div className="input-container">
                                  <label className="input-label">Formats</label>
                                  &nbsp;&nbsp;
                                  <Input
                                    placeholder="Eg EXP,HUS,PES,DST,SEW,JEF,PCS,VP3,XXX,PDF"
                                    className="rg-input"
                                    value={selectedProduct.formats}
                                    name="formats"
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="input-container">
                                  <label className="input-label">Quantity</label>
                                  &nbsp;&nbsp;
                                  <Input
                                    placeholder="Eg 1 Size"
                                    name="quantity"
                                    className="rg-input"
                                    value={selectedProduct.quantity}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                            )}
                          {/* <label className="label py-2">Tags</label>

                          <select
                            value={selectedTags}
                            onChange={handleSelectChangeTag}
                            style={{ width: "100%" }}
                            className="rg-input"
                          >
                            {tags.map((tag: any, index: any) => (
                              <option key={index} value={tag.value}>
                                {tag.label}
                              </option>
                            ))}
                          </select> */}

                          {/* <select
                            value={selectedTags}
                            onChange={handleSelectedProdTags}
                            style={{ width: "100%" }}
                            className="rg-input"
                          >
                            {tags.map((tag: any, index: any) => (
                              <option key={index} value={tag.value}>
                                {tag.label}
                              </option>
                            ))}
                          </select> */}

                          <label className="label py-2">Keywords</label>
                          <Input
                            name="keywords"
                            placeholder="Time,Dog"
                            value={selectedProduct.keywords}
                            onChange={handleInputChange}
                          />
                          <label className="label py-2">URL format:</label>
                          <Input
                            name="slug"
                            placeholder=""
                            value={selectedProduct.slug}
                            onChange={handleInputChange}
                          />

                          <label className="label py-2">
                            Product Description
                          </label>

                          <Editor
                            value={selectedProduct.description || ""}
                            onTextChange={(e) =>
                              e &&
                              setSelectedProduct({
                                ...selectedProduct,
                                description: e.htmlValue || "",
                              })
                            }
                            style={{
                              height: "320px",
                              lineHeight: "normal",
                              width: "100%",
                              wordWrap: "break-word",
                            }}
                          />
                        </div>
                      </div>
                      <div className="text-center py-3">
                        <Button
                          variant="contained"
                          className="submit-btn btn apply-btn"
                          onClick={handleUpdateProduct}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </Box>
                )}
              </Modal.Body>
            </Modal>

            {/* Discount model handleClose*/}
            <Modal
              show={openDiscount}
              onHide={handleCloses}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Modal.Header className="d-flex justify-content-between">
                <Modal.Title>Discount Price</Modal.Title>
                <MdClear onClick={handleCloses} style={{ cursor: "pointer" }} />
              </Modal.Header>
              <Modal.Body>
                <Box sx={{ ...style }}>
                  <Radio.Group
                    onChange={onChange}
                    value={value}
                    className="pb-3"
                  >
                    <Radio
                      value={"escalation"}
                      style={{ fontSize: "16px", fontWeight: 600 }}
                    >
                      Price escalation
                    </Radio>
                    <Radio
                      value={"reduction"}
                      style={{ fontSize: "16px", fontWeight: 600 }}
                    >
                      Price reduction
                    </Radio>
                  </Radio.Group>
                  <br />
                  <input
                    placeholder="Eg:10"
                    className="p-1 Date"
                    onChange={(e) =>
                      setDiscountData({
                        ...discountData,
                        pricePercentage: Number(e.target.value),
                      })
                    }
                  />
                  <br />
                  <label
                    style={{ fontSize: "16px", fontWeight: 600 }}
                    className="py-2"
                  >
                    Valid Till
                  </label>
                  <br />
                  <input
                    type="date"
                    className="Date p-1  "
                    onChange={(e) =>
                      setDiscountData({
                        ...discountData,
                        priceValidityDate: e.target.value,
                      })
                    }
                  />
                  <div className="text-start py-3">
                    <Button
                      style={{ backgroundColor: "red" }}
                      onClick={handleupdateDiscount}
                    >
                      Update
                    </Button>
                  </div>
                </Box>
              </Modal.Body>
            </Modal>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Products;
